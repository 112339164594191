import { Grid } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { LoginDialogContext } from "../../components/LoginDialog"
import useAlert from "../../contexts/AlertContext"
import { CalculosContext } from "../../contexts/CalculosContext"
import useEndereco from "../../contexts/EnderecoContext"
import useParametros from "../../contexts/ParametrosContext"
import SiteContext from "../../contexts/SiteContext"
import { useTextos } from "../../contexts/TextosContext"
import api from "../../services/api"
import {
    AtualizarDispositivoUsuario,
    identificarEstabelecimentoSelecionado,
    isNumeric,
    LoginGeral,
    RedefinirSenha,
    RegistrarLogErro,
    verificarClientes,
} from "../../services/functions"
import DialogCadastroPorMensagem from "./DialogCadastroPorMensagem"
import LoginContext from "./LoginContext"
import OutrasOpcoes from "./OutrasOpcoes"
import "./styles.css"
import VerificarTelefone from "./VerificarTelefone"

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    root: {
        width: "100%",
        margin: "1em 0",
    },
    pos: {
        marginBottom: 12,
    },
    envioCodigo: {
        flexDirection: "column",
    },
    envioCodigo_botao: {
        backgroundColor: "#28a745",
        margin: "4px",
        "&:hover": {
            backgroundColor: "#28a745",
        },
    },
    envioCodigo_sms: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "4px",
        color: "black",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    envioCodigo_textoCentral: {
        marginTop: "24px",
        marginBottom: "12px",
        color: "black",
        [theme.breakpoints.down("xs")]: {
            marginTop: "16px",
            marginBottom: "16px",
        },
    },
    envioSenha: {
        [theme.breakpoints.down("xs")]: {
            marginTop: "8px",
            flexDirection: "column",
            // justifyContent: 'center',
            alignItems: "center",
            gap: "8px",
        },
        "& button": {
            width: "100%",
            overflow: "hidden",
            "& div": {
                mixBlendMode: "hard-light",
                position: "absolute",
                width: "100%",
                height: "100%",
                transition: "transform 1s",
            },
        },
    },
}))

const recuperarAcesso = async (data, aplicativoDados) => {
    try {
        const response = await RedefinirSenha(data, aplicativoDados)
        if (response.retornoErro || response.codErro) {
            return response.mensagem
        } else {
            return "success"
        }
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "recuperarSenha", data)
        return `Procure os desenvolvedores! Erro: ${error.message}`
    }
}

const verificarExistenciaDoCliente = async (
    dadoInserido,
    nomeEstabelecimento,
    idEstabelecimento
) => {
    const data = {
        idEstabelecimento: idEstabelecimento,
        nomeCliente: dadoInserido,
        maxResultado: 100,
        token: "a~I?;;+_@!AL^",
    }
    try {
        const response = await verificarClientes(data, nomeEstabelecimento)
        if (response.retornoErro) {
            return response.mensagem
        }
        return "success"
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "verificarExistencia", data)
        return "Erro inesperado ao verificar existência"
    }
}

const getListaClientes = async (dadoInserido, nomeEstabelecimento, idEstabelecimento) => {
    const data = {
        idEstabelecimento: idEstabelecimento,
        nomeCliente: dadoInserido,
        maxResultado: 100,
        token: "a~I?;;+_@!AL^",
    }
    try {
        const response = await verificarClientes(data, nomeEstabelecimento)

        return response
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "verificarExistencia", data)
    }
}

const BotoesRedefinicaoSenha = props => {
    const { aplicativoDados, estabelecimentoAtual } = useContext(SiteContext)
    const textos = useTextos()

    const classes = useStyles()

    const [quantidadeEnvio, setQuantidadeEnvio] = useState(0)
    const [enviouWpp, setEnviouWpp] = useState(false)
    const [tempoEsperaBotoes, setTempoEsperaBotoes] = useState(0)
    const [tempoEsperaTotal, setTempoEsperaTotal] = useState(0)
    const [loop, setLoop] = useState()

    const [urlEnvioWpp, setUrlEnvioWpp] = useState()
    const getUrlEnvioWPP = async () => {
        if (estabelecimentoAtual?.id) {
            const response = await api.get("cliente/URLLoginWhatsapp/" + estabelecimentoAtual.id)

            setUrlEnvioWpp(response.data)

            return
        }

        for (const estab of aplicativoDados.estabelecimentos) {
            const response = await api.get("cliente/URLLoginWhatsapp/" + estab.id)

            if (response.data.length && typeof response.data === "string") {
                setUrlEnvioWpp(response.data)
                return
            }
        }
    }

    const enviarPorWpp = () => {
        if (tempoEsperaBotoes)
            props.alertStart(
                textos.aguardeTempoEnviar.replace("$tempo", tempoEsperaBotoes),
                "warning"
            )
        else if (urlEnvioWpp) {
            window.open(urlEnvioWpp, "_blank")
            setEnviouWpp(true)
        }
    }

    useEffect(() => {
        if (loop) clearInterval(loop)

        if (quantidadeEnvio > 0) {
            if (quantidadeEnvio === 1) {
                setTempoEsperaBotoes(30)
                setTempoEsperaTotal(30)
            } else if (quantidadeEnvio === 2) {
                setTempoEsperaBotoes(60)
                setTempoEsperaTotal(60)
            } else if (quantidadeEnvio > 2) {
                setTempoEsperaBotoes(300)
                setTempoEsperaTotal(300)
            }

            setLoop(
                setInterval(() => {
                    setTempoEsperaBotoes(prev => Math.max(prev - 1, 0))
                }, 1000)
            )
        }
    }, [quantidadeEnvio])

    useEffect(() => {
        getUrlEnvioWPP()
    }, [])

    const translatePorcentagem = tempoEsperaTotal
        ? (100 * (tempoEsperaTotal - tempoEsperaBotoes)) / tempoEsperaTotal
        : 0

    return (
        <Grid className={classes.envioSenha} container spacing={1}>
            {urlEnvioWpp ? (
                <Grid item xs={12}>
                    <Button
                        size="small"
                        variant="outlined"
                        style={{ color: `${aplicativoDados.corSitePrimaria}` }}
                        onClick={enviarPorWpp}
                    >
                        {tempoEsperaBotoes ? (
                            <div
                                style={{
                                    backgroundColor: aplicativoDados.corSitePrimaria,
                                    transform: `translateX(-${translatePorcentagem}%)`,
                                }}
                            />
                        ) : null}
                        {textos.enviarSenhaWPP}
                    </Button>
                </Grid>
            ) : null}
            {!urlEnvioWpp || enviouWpp ? (
                <Grid item xs={12}>
                    <Button
                        size="small"
                        variant="outlined"
                        style={{ color: `${aplicativoDados.corSitePrimaria}` }}
                        onClick={() => {
                            if (tempoEsperaBotoes)
                                props.alertStart(
                                    textos.aguardeTempoEnviar.replace("$tempo", tempoEsperaBotoes),
                                    "warning"
                                )
                            else {
                                props.enviarPorSMS()
                                setQuantidadeEnvio(prev => prev + 1)
                            }
                        }}
                    >
                        {tempoEsperaBotoes ? (
                            <div
                                style={{
                                    backgroundColor: aplicativoDados.corSitePrimaria,
                                    transform: `translateX(-${translatePorcentagem}%)`,
                                }}
                            />
                        ) : null}
                        {textos.enviarSenhaSMS}
                    </Button>
                </Grid>
            ) : null}
            <Grid item xs={12}>
                <Button
                    size="small"
                    variant="outlined"
                    style={{ color: `${aplicativoDados.corSitePrimaria}` }}
                    onClick={() => {
                        if (tempoEsperaBotoes)
                            props.alertStart(
                                textos.aguardeTempoEnviar.replace("$tempo", tempoEsperaBotoes),
                                "warning"
                            )
                        else {
                            props.enviarPorEmail()
                            setQuantidadeEnvio(prev => prev + 1)
                        }
                    }}
                >
                    {tempoEsperaBotoes ? (
                        <div
                            style={{
                                backgroundColor: aplicativoDados.corSitePrimaria,
                                transform: `translateX(-${translatePorcentagem}%)`,
                            }}
                        />
                    ) : null}
                    {textos.enviarSenhaEmail}
                </Button>
            </Grid>
        </Grid>
    )
}

export default function Login({
    telaCardapio = false,
    queroMeCadastrar = false,
    location,
    telefone = "",
    setOpenTelaLogin = () => {},
    confirmacaoInformacoes = () => {},
} = {}) {
    const {
        aplicativoDados,
        setAplicativoDados,

        estabelecimentoAtual,
        setEstabelecimentoAtual,

        loading,
        setLoading,

        usuarioLogado,
        setUsuarioLogado,

        carrinho,
        setCarrinho,

        cardapio,
        setCardapio,
    } = useContext(SiteContext)
    const textos = useTextos()
    const { enderecoAtual, selecionarEndereco } = useEndereco()

    const { abrirDialogLogin, setAbrirDialogLogin } = useContext(LoginDialogContext)

    localStorage.removeItem("tipoLoginCF")

    const apenasLoginTelefone = aplicativoDados?.loginApenasTelefone
    const [usuario, setUsuario] = useState("")
    const [senha, setSenha] = useState("")
    const [smsOrWhatsapp, setSmsOrWhatsapp] = useState("")
    const [CadastroTelefone, setCadastroTelefone] = useState(false)
    const [telaCodigo, setTelaCodigo] = useState(false)
    const history = useHistory()
    //LOGIN TOKEN
    const search = window.location.search
    const params = new URLSearchParams(search)
    // const [whats, setWhats] = useState(params.get('whats'));
    const [chave] = useState(params.get("chave"))
    const cupom = params.get("cupom")
    const [lojaParams, setLojaParams] = useState(params.get("loja"))

    const FacebookId = params.get("facebookId")
    const FacebookEmail = params.get("facebookEmail")
    const FacebookNome = params.get("facebookNome")

    const [tentativaIzza, setTentativaIzza] = useState(0)
    const [tentativaFacebookLogin, setTentativaFacebookLogin] = useState(0)

    const classes = useStyles()

    const [abrirDialogCadastroIzza, setAbrirDialogCadastroIzza] = useState(false)
    const [verificarTelefone, setVerificarTelefone] = useState(false)
    const [inputEmailTelefone, setInputEmailTelefone] = useState(true)
    const [inputSenha, setInputSenha] = useState(false)
    const [emailTelefone, setEmailTelefone] = useState(telefone ? telefone : "")
    const [usuariosEmailTelefone, setUsuariosEmailTelefone] = useState([])
    const [outrasOpcoes, setOutrasOpcoes] = useState(false)

    // FUNCOES

    const { calcularValorTotal, adicionarEndereco, ObterCardapioCalculo } =
        useContext(CalculosContext)

    const parametros = useParametros()

    const alertStart = useAlert()

    const loginPedidoMesa = async () => {
        setLoading(true)

        try {
            var data = {}

            if (params.get("mesa")) {
                if (
                    isNumeric(params.get("mesa")) &&
                    estabelecimentoAtual &&
                    !estabelecimentoAtual.possuiPedidoMesa
                ) {
                    history.push("/")
                    setLoading(false)
                    return
                }

                if (params.get("mesa") !== "--")
                    window.setCookie("mesa-salva", params.get("mesa"), 0.2)

                const nomeMesa =
                    parametros.PUBLIC_PADRAO_PEDIDO_MESA ?? isNumeric(params.get("mesa"))
                        ? "Mesa " + params.get("mesa")
                        : params.get("mesa")

                data = {
                    appNome: aplicativoDados.appNome,
                    nome: nomeMesa,
                    tipoUsuario: 2, //0-permanente, 1-temporario/semcadastro, 2-temporario/mesa
                    telefone: `${
                        Math.floor(Math.random() * (10000000000 - 99999999999)) + 99999999999
                    }`,
                    token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
                }
            } else if (params.get("comanda")) {
                data = {
                    appNome: aplicativoDados.appNome,
                    nome: "Comanda " + params.get("comanda"),
                    tipoUsuario: 2, //0-permanente, 1-temporario/semcadastro, 2-temporario/mesa
                    telefone: `${
                        Math.floor(Math.random() * (10000000000 - 99999999999)) + 99999999999
                    }`,
                    token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
                }
            }

            await api
                .post("clientefiel/CadastrarUsuarioMesa", data, {
                    headers: { "Content-Type": "application/json" },
                })
                .then(async response => {
                    console.log("CadastrarUsuarioMesa", response)

                    if (typeof response.data != "string" && !response.data.codErro > 0) {
                        window.dispatchEvent(new CustomEvent("loginMesa"))
                        response.data.logado = true
                        response.data.cliente.telefone = ""
                        setUsuarioLogado(response.data)
                        response.data.cliente.nome = ""
                        localStorage.setItem("usuarioPedidoMesaCF_Date", new Date())
                        localStorage.setItem("usuarioPedidoMesaCF", JSON.stringify(response.data))

                        selecionarEndereco({})

                        const lojaId = params.get("loja")
                        let estab = null
                        aplicativoDados.estabelecimentos.forEach(loja => {
                            if (loja.id == lojaId || loja.codigo == lojaId) {
                                estab = loja
                            }
                        })

                        buscaCardapioInicial({
                            usuarioLogado: response.data,
                            estabelecimentoAtual: estab,
                            enderecoAtual: {},
                        })
                    } else {
                        setLoading(false)
                        alertStart("Houve um erro ao buscar mesa", "error")
                    }
                })
        } catch (error) {
            RegistrarLogErro(error, "CadastrarUsuarioMesa", data)
            alertStart("Erro inesperado ao buscar mesa", "error")
            setLoading(false)
        }
    }

    const irParaDelivery = () => {
        const params = new URLSearchParams(window.location.search)

        let stringParams = []
        params.forEach((param, key) => {
            stringParams.push(key + "=" + param)
        })
        stringParams = stringParams.join("&")

        let baseURL = "/delivery"

        if (!stringParams.includes("loja="))
            baseURL += identificarEstabelecimentoSelecionado(aplicativoDados)

        if (stringParams) {
            if (baseURL.includes("?")) baseURL += "&"
            else baseURL += "?"
        }

        history.push(baseURL + stringParams)
    }

    async function buscaCardapioInicial({
        usuarioLogado,
        estabelecimentoAtual,
        enderecoAtual,
    } = {}) {
        try {
            // if(!estabelecimentoAtual){
            // console.log("ESTABELECIMENTO ATUAL VAZIO")
            //   history.push(`/estabelecimento`)
            //   return
            // }
            setEstabelecimentoAtual(estabelecimentoAtual)
            if (aplicativoDados.possuiDelivery === true) {
                await ObterCardapioCalculo({
                    usuarioLogado: usuarioLogado,
                    estabelecimentoAtual: estabelecimentoAtual,
                    enderecoAtual: enderecoAtual,
                    forcarAtualicao: true,
                })
                if (
                    JSON.parse(localStorage.getItem("usuarioPedidoMesaCF"))?.logado ||
                    usuarioLogado.estabelecimentos.length === 1 ||
                    estabelecimentoAtual?.id
                ) {
                    irParaDelivery()
                } else if (usuarioLogado.estabelecimentos.length > 1) {
                    history.push(`/estabelecimento`)
                }
            } else {
                if (JSON.parse(localStorage.getItem("usuarioPedidoMesaCF"))?.logado) {
                    history.push(
                        `/delivery${identificarEstabelecimentoSelecionado(aplicativoDados)}`
                    )
                }
                history.push(`/fidelidade${identificarEstabelecimentoSelecionado(aplicativoDados)}`)
            }
        } catch (error) {
            RegistrarLogErro(error, "buscaCardapioInicial", {
                usuarioLogado: usuarioLogado,
                estabelecimentoAtual: estabelecimentoAtual,
                enderecoAtual: enderecoAtual,
            })
            alertStart("Erro nao identificado buscaCardapioInicial" + error, "error")
            console.log(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (!!chave) {
            setLoading(true)
        }
    }, [])

    useEffect(() => {
        const loginIzza = async () => {
            await realizarLogin_tokenizza(chave)

            const idPedidoRedirecionado = params.get("pedido")
            if (!!idPedidoRedirecionado) {
                history.push(
                    `/delivery/pedido${identificarEstabelecimentoSelecionado(
                        aplicativoDados,
                        idPedidoRedirecionado,
                        77
                    )}`
                )
            }
        }

        if (tentativaIzza === 0) {
            if (!!chave) {
                loginIzza()
            }
        }
    }, [tentativaIzza])

    useEffect(() => {
        if (params.get("mesa") || params.get("comanda")) {
            loginPedidoMesa()
        }
    }, [])

    useEffect(() => {
        if (tentativaFacebookLogin === 0) {
            if (FacebookId) {
                loginUsuarioSenha({
                    FacebookId,
                    FacebookEmail,
                    FacebookNome,
                })
            }
        }
    }, [tentativaFacebookLogin])

    async function realizarLogin_tokenizza(chave) {
        //console.log("realizarLogin_tokenizza")
        setTentativaIzza(1)
        setLoading(true)

        let estabelecimento = estabelecimentoAtual
        //  alertStart("Estou tentando te conectar diretamente com o link clicado no whatsapp!", "warning")
        const clientefiel = {
            appNome: aplicativoDados.appNome,
            cliente: {
                email: chave,
                hashSenha: chave,
                appNome: aplicativoDados.appNome,
                token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
            },
            localizacao: {
                latitude: "",
                longitude: "",
                token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
                appNome: aplicativoDados.appNome,
            },
            token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
        }
        try {
            const response = await api.post("clientefiel/LoginToken", clientefiel, {
                headers: { "Content-Type": "application/json" },
            })

            console.log("loginToken", response)
            if (response.data.codErro > 0) {
                window.dispatchEvent(new CustomEvent("loginIzzaExpirado"))

                localStorage.setItem("tipoLoginCF", "apenasTelefone")
                console.log("loginToken", lojaParams)
                if (lojaParams) {
                    history.push(`/delivery?loja=${lojaParams}`)
                } else {
                    history.push(`/`)
                }
            } else {
                window.dispatchEvent(new CustomEvent("loginIzza"))

                localStorage.setItem("usuarioIzzaCF", JSON.stringify(response.data))
                setUsuarioLogado(response.data)

                if (cupom) {
                    localStorage.setItem(
                        "cupomLink",
                        JSON.stringify({
                            codigo: cupom,
                            idCliente: response.data.cliente.id,
                        })
                    )
                }

                if (!enderecoAtual.bairro) {
                    selecionarEndereco({})
                }

                if (lojaParams) {
                    const estabelecimentoEncontrado = response.data.estabelecimentos.filter(
                        e => e.id == lojaParams || e.codigo == lojaParams
                    )
                    if (estabelecimentoEncontrado.length > 0) {
                        estabelecimento = estabelecimentoEncontrado[0]
                    } else {
                        estabelecimento = response.data.estabelecimentos[0]
                    }
                } else {
                    var estabelecimentos = response.data.estabelecimentos
                    if (estabelecimentos && estabelecimentos.length == 1) {
                        estabelecimento = estabelecimentos[0]
                    }
                }

                console.log("estabelecimentoestabelecimento", estabelecimento)

                if (params.get("pedido")) {
                    setLoading(false)
                    return
                }

                if (estabelecimento && response.data.estabelecimentos.length > 0) {
                    window.setTimeout(async function () {
                        await buscaCardapioInicial({
                            usuarioLogado: response.data,
                            estabelecimentoAtual: estabelecimento,
                            enderecoAtual: {},
                        })
                    }, 1000)
                } else {
                    history.push(`/`)
                    //alertStart("Nenhum estabelecimento disponivel nesse aplicativo", "error")
                }
            }
        } catch (error) {
            RegistrarLogErro(error, "LoginToken", clientefiel)
            // alertStart("Erro nao identificado LoginToken", "error")
            history.push("/")
            setLoading(false)
        }
        setLoading(false)
    }

    async function enviarCodigoTelefone(props) {
        try {
            const temTelefone =
                emailTelefone?.replace(/\D/gim, "")?.length === 10 ||
                emailTelefone?.replace(/\D/gim, "")?.length === 11

            if (!emailTelefone || !temTelefone) {
                alertStart("Não identificamos seu telefone.", "error")
                return false
            }

            setLoading(true)

            const data = {
                appNome: aplicativoDados.appNome,
                cliente: {
                    telefone: emailTelefone,
                    appNome: aplicativoDados.appNome,
                    token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
                },
                localizacao: {
                    latitude: "",
                    longitude: "",
                    token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
                    appNome: aplicativoDados.appNome,
                },
                token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
            }

            const smsOrWhatsapp = "WHATSAPP"
            let urlEnviarCodigo = ""
            if (smsOrWhatsapp === "WHATSAPP") {
                window.dispatchEvent(new CustomEvent("loginWhatsapp"))
                urlEnviarCodigo = "clientefiel/oauth/GerarChaveTelefoneWhatsApp"
            } else if (smsOrWhatsapp === "SMS") {
                window.dispatchEvent(new CustomEvent("loginSms"))
                urlEnviarCodigo = "clientefiel/oauth/GerarChaveTelefoneSms"
            }

            api.post(urlEnviarCodigo, data, {
                headers: {
                    "Content-Type": "application/json",
                    Plataforma: "Site",
                    quantidadeNumero: 4,
                },
            }).then(resposta => {
                //console.log('respostaa', resposta, data, urlEnviarCodigo)
                if (resposta.data.codErro > 0) {
                    // ERRO
                    alertStart(resposta.data.mensagem, "error")
                    return false
                } else {
                    // DEU CERTO
                    setVerificarTelefone(true)
                    alertStart(
                        `Código de verificação foi enviado por ${smsOrWhatsapp.toLocaleLowerCase()}!`,
                        "success"
                    )
                }
            })
        } catch (error) {
            RegistrarLogErro(error, "enviarCodigoTelefone")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
        setLoading(false)
    }

    async function loginUsuarioSenha(props = {}) {
        const {
            usuario = emailTelefone,
            hashSenha = senha,
            formaLogin = "usuarioSenha",
            FacebookId,
            FacebookEmail,
            FacebookNome,
        } = props
        setLoading(true)
        let clientefiel = {
            appNome: aplicativoDados.appNome,
            cliente: {
                appNome: aplicativoDados.appNome,
                token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
            },
            localizacao: {
                latitude: "",
                longitude: "",
                token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
                appNome: aplicativoDados.appNome,
            },
            token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
        }
        try {
            if (!FacebookId && (FacebookEmail || FacebookNome)) {
                alertStart("Seu Facebook não permite esse tipo de login!", "warning")
                setLoading(false)
                return false
            } else if (FacebookId) {
                setTentativaFacebookLogin(1)
                clientefiel.cliente.email = FacebookEmail
                    ? FacebookEmail
                    : `${FacebookId}@facebook.com.br`
                clientefiel.cliente.facebookId = FacebookId
                clientefiel.cliente.tipoUsuario = 0
                clientefiel.cliente.nome = FacebookNome ? FacebookNome : "Nome Não Identificado"
            } else {
                clientefiel.cliente.email = usuario
                clientefiel.cliente.hashSenha = hashSenha
            }
            const response = await LoginGeral(clientefiel, aplicativoDados)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                setLoading(false)
                return
            }
            window.dispatchEvent(new CustomEvent(formaLogin))
            if (localStorage.getItem("tokenNotificationFirebase")) {
                if (
                    response.cliente.codigoDispositivo !==
                    localStorage.getItem("tokenNotificationFirebase")
                ) {
                    response.cliente.codigoDispositivo = localStorage.getItem(
                        "tokenNotificationFirebase"
                    )
                    response.cliente.token = `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`
                    AtualizarDispositivoUsuario(response.cliente, aplicativoDados)
                }
            }
            if (telaCardapio && !abrirDialogLogin) {
                confirmacaoInformacoes({ usuario: response, telaLogin: true })
            } else {
                setAbrirDialogLogin(false)
                setUsuarioLogado(response)
                if (!enderecoAtual.bairro) {
                    selecionarEndereco({})
                } else if (enderecoAtual?.bairro && !enderecoAtual?.id) {
                    await adicionarEndereco({
                        usuarioLogado: response,
                        enderecoAtual: enderecoAtual,
                    })
                }
                if (!carrinho) {
                    setCarrinho({})
                }
                console.log("EstabelecimentoAtual:::", estabelecimentoAtual)
                let estab = estabelecimentoAtual
                if (!estab?.id) {
                    estab = response.estabelecimentos
                    // estab = response.estabelecimentos[0]
                }
                console.log("EstabelecimentoAtual:::", estab)
                if (Array.isArray(estab)) {
                    estab = estab[0]
                    console.log("EstabelecimentoAtual:::[0]", estab)
                }

                if (response.estabelecimentos.length > 0) {
                    await buscaCardapioInicial({
                        usuarioLogado: response,
                        estabelecimentoAtual: estab,
                        enderecoAtual,
                    })
                } else {
                    setLoading(false)
                    alertStart("Nenhum estabelecimento disponivel para esse usuario", "error")
                }
            }
        } catch (error) {
            RegistrarLogErro(error, "loginUsuarioSenha", clientefiel)
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
            console.error(error)
            setLoading(false)
        }
    }

    const VerificarExistenciaUsuario = async e => {
        try {
            const temTelefone =
                emailTelefone?.replace(/\D/gim, "")?.length === 10 ||
                emailTelefone?.replace(/\D/gim, "")?.length === 11
            const temEmail = emailTelefone.includes("@") && emailTelefone.includes(".")

            if (!emailTelefone || (!temTelefone && !temEmail)) {
                alertStart("Não identificamos seu email ou telefone.", "error")
                return false
            }

            setLoading(true)
            const data = {
                idEstabelecimento: aplicativoDados.estabelecimentos[0].id,
                nomeCliente: emailTelefone,
                maxResultado: 100,
                token: "a~I?;;+_@!AL^",
            }
            // const response = await listarClientes(data, aplicativoDados.appNome)
            const response = await verificarClientes(data, aplicativoDados.appNome)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                setLoading(false)
                return false
            } else {
                setLoading(false)
                return response
            }
        } catch (error) {
            RegistrarLogErro(error, "verificarExistenciaUsuario")
            alertStart("Erro inesperado ao verificarExistencia" + error, "error")
            console.error(error)
        }
        setLoading(false)
        return false
    }

    const verificarExistencia = async e => {
        const response = await VerificarExistenciaUsuario()
        if (response.length > 0) {
            setUsuariosEmailTelefone(response)
            setInputSenha(true)
        } else {
            if (emailTelefone.replace(/\D/gim, "")?.length >= 10) {
                history.push(
                    "/cadastro?telefone=" +
                        emailTelefone.replace(/\D/gim, "") +
                        "&voltarTelaCarrinho=" +
                        queroMeCadastrar
                )
            } else if (
                emailTelefone.includes("@") &&
                emailTelefone.includes(".") + "&voltarTelaCarrinho=" + queroMeCadastrar
            ) {
                history.push("/cadastro?email=" + emailTelefone)
            } else {
                history.push("/cadastro" + "?voltarTelaCarrinho=" + queroMeCadastrar)
            }
            setAbrirDialogLogin(false)
            alertStart("Por favor, complete seu cadastro", "warning")
        }
    }

    const paramsUrl = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    })
    useEffect(() => {
        if (!!paramsUrl.usuario) setEmailTelefone(paramsUrl.usuario)
    }, [])

    if (!!chave)
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100vw",
                    height: "100vh",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <img src={aplicativoDados.urlLogo} style={{ height: "150px" }} alt="" />
                <Typography component="h1" variant="h5" className="mt-3">
                    {textos.conectando}...
                </Typography>
                <Typography component="h1" variant="h5" className="mt-3">
                    {textos.porFavorAguarde}
                </Typography>
            </div>
        )

    const verificarDadoInserido = async () => {
        setLoading(true)
        let retorno
        if (emailTelefone?.includes("@") && emailTelefone?.includes(".")) {
            retorno = "email"
        } else if (
            emailTelefone.replace(/\D/gim, "")?.length === 10 ||
            emailTelefone?.replace(/\D/gim, "")?.length === 11
        ) {
            retorno = "telefone"
        } else {
            retorno = "indefinido"
            alertStart("Não identificamos seu email ou telefone.", "error")
        }
        const response = await verificarExistenciaDoCliente(
            emailTelefone,
            aplicativoDados.appNome,
            aplicativoDados.estabelecimentos[0].id
        )
        setLoading(false)
        if (response !== "success") {
            alertStart(response, "error")
            return false
        }
        return retorno
    }

    const recuperarSenha = async metodoDeEnvio => {
        setLoading(true)
        let tipoDoDado

        await verificarDadoInserido().then(retorno => {
            if (!retorno) {
                setLoading(false)
                return
            } else tipoDoDado = retorno
        })

        if (metodoDeEnvio === 0) {
            const listaClientes = await getListaClientes(
                emailTelefone,
                aplicativoDados.appNome,
                aplicativoDados.estabelecimentos[0].id
            )

            const emailCliente = emailTelefone.includes("@")
                ? emailTelefone
                : listaClientes.find(
                      cadastro => cadastro.email && !cadastro.email.includes(cadastro.telefone)
                  )?.email

            if (emailCliente) {
                const data = {
                    appNome: aplicativoDados.appNome,
                    email: emailCliente,
                    tipoVerificacao: metodoDeEnvio,
                    token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
                }
                const response = await recuperarAcesso(data, aplicativoDados)

                if (response !== "success") {
                    alertStart(response, "error")
                    setLoading(false)
                    return
                }
            } else {
                alertStart("Não há email cadastrado", "warning")
                return
            }
        } else {
            const data = {
                appNome: aplicativoDados.appNome,
                telefone: emailTelefone,
                tipoVerificacao: metodoDeEnvio,
                token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
            }
            const response = await recuperarAcesso(data, aplicativoDados)

            if (response !== "success") {
                alertStart(response, "error")
                setLoading(false)
                return
            }
        }

        //tipo  =>  email = 0, sms = 1, wapp = 2
        switch (metodoDeEnvio) {
            case 0:
                alertStart("Enviamos uma nova senha para seu Email.", "success")
                break
            case 1:
                alertStart("Enviamos uma nova senha para seu SMS.", "success")
                break
            case 2:
                alertStart("Enviamos uma nova senha para seu WhatsApp.", "success")
                break
        }
        setLoading(false)
    }

    return (
        <LoginContext.Provider
            value={{
                emailTelefone,
                setEmailTelefone,
                setOutrasOpcoes,
                outrasOpcoes,
                VerificarExistenciaUsuario,
                setInputSenha,
                enviarCodigoTelefone,
            }}
        >
            <Container component="main" maxWidth="xs">
                <CssBaseline />

                <div className={!telaCardapio ? classes.paper : {}}>
                    {!telaCardapio ? (
                        <>
                            <img src={aplicativoDados.urlLogo} style={{ height: "150px" }} alt="" />
                            <Typography component="h1" variant="h5" className="mt-3">
                                {telaCodigo
                                    ? `${textos.verifiqueCodigoEnviado} ${smsOrWhatsapp}`
                                    : textos.conectarAoUsuario}
                            </Typography>
                        </>
                    ) : null}
                    <form
                        className={`${classes.form} container`}
                        onSubmit={e => {
                            e.preventDefault()
                            if (inputEmailTelefone && apenasLoginTelefone) {
                                enviarCodigoTelefone()
                            } else if (inputEmailTelefone && !inputSenha) {
                                verificarExistencia()
                            } else if (inputEmailTelefone && inputSenha) {
                                document.activeElement.blur()
                                loginUsuarioSenha()
                            }
                        }}
                    >
                        {inputEmailTelefone ? (
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                onChange={e => {
                                    if (inputSenha) {
                                        setInputSenha(false)
                                        setUsuariosEmailTelefone([])
                                        setSenha("")
                                    }
                                    setEmailTelefone(e.target.value)
                                }}
                                id="usuario"
                                value={emailTelefone}
                                label={
                                    apenasLoginTelefone
                                        ? textos.telefone
                                        : `${textos.email} ou ${textos.telefone}`
                                }
                                name="usuario"
                            />
                        ) : null}

                        {inputSenha ? (
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                value={senha}
                                onChange={e => setSenha(e.target.value)}
                                name="senha"
                                label={textos.senha}
                                type="password"
                                id="senha"
                                autoFocus
                            />
                        ) : null}

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            {textos.continuar}
                        </Button>
                        {!apenasLoginTelefone && inputSenha ? (
                            <BotoesRedefinicaoSenha
                                enviarPorEmail={() => recuperarSenha(0)}
                                enviarPorSMS={() => recuperarSenha(1)}
                                alertStart={alertStart}
                            />
                        ) : null}
                    </form>
                    {!telaCardapio ? (
                        <Button
                            type="submit"
                            fullWidth
                            style={{ marginTop: "2em" }}
                            onClick={() => {
                                setOutrasOpcoes(true)
                            }}
                            color="default"
                        >
                            {textos.outrasOpcoes}
                        </Button>
                    ) : null}
                    <DialogCadastroPorMensagem
                        loginUsuarioSenha={loginUsuarioSenha}
                        abrirDialogCadastroIzza={abrirDialogCadastroIzza}
                        setAbrirDialogCadastroIzza={setAbrirDialogCadastroIzza}
                        enviarPorWpp={() => recuperarSenha(2)}
                        enviarPorSMS={() => recuperarSenha(1)}
                        senha={senha}
                        setSenha={setSenha}
                    />
                    <VerificarTelefone
                        setVerificarTelefone={setVerificarTelefone}
                        verificarTelefone={verificarTelefone}
                        telefone={emailTelefone}
                        loginUsuarioSenha={loginUsuarioSenha}
                    />
                    <OutrasOpcoes /> {/*TODO: CHECAR A NECESSIDADE DISSO, PARECE QUE PRECISA*/}
                </div>
            </Container>
        </LoginContext.Provider>
    )
}
