import { useSnackbar } from "notistack"
import { createContext, useContext } from "react"

const AlertContext = createContext(null)

export const AlertContextProvider = ({ children }) => {
    const { enqueueSnackbar } = useSnackbar()
    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const value = { alertStart }

    return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
}

export default function useAlert() {
    const context = useContext(AlertContext)

    if (!context) throw new Error("useEndereco must be within a Context Provider")

    return context.alertStart
}
