import { createContext, useContext, useEffect, useState } from "react"
import idiomas from "../assets/idiomas.json"
import useParametros from "./ParametrosContext"

export const TextosContext = createContext(null)

export function TextosContextProvider(props) {
    const parametros = useParametros()
    const [idioma, setIdioma] = useState(parametros?.PUBLIC_IDIOMA ?? "pt-br")

    useEffect(() => {
        if (parametros?.PUBLIC_IDIOMA) setIdioma(parametros.PUBLIC_IDIOMA)
    }, [parametros])

    const textos = idiomas[idioma]

    return (
        <TextosContext.Provider
            value={{
                idioma,
                setIdioma,
                textos,
                idiomas,
            }}
        >
            {props.children}
        </TextosContext.Provider>
    )
}

export const useTextos = () => {
    const context = useContext(TextosContext)

    if (!context) throw new Error("useTextos must be within a Context Provider")

    return context.textos
}
