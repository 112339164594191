import { Button, makeStyles, Paper, Typography } from "@material-ui/core"
import { useState } from "react"
import { useTextos } from "../contexts/TextosContext"

const useStyles = makeStyles(theme => ({
    avisoLgpd: {
        backgroundColor: "rgba(0,0,0,0.7)",
        position: "fixed",
        padding: "8px 0",
        left: "0",
        zIndex: "1300",
        width: "100%",
        textAlign: "center",
        transition: "bottom 0.75s linear, background-color 0.3s",
        [theme.breakpoints.up("md")]: {
            transition: "bottom 0.75s cubic-bezier(0, 1.42, 0.75, 1.29), background-color 0.3s",
        },
        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.85)",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "4px",
        },
    },
    botaoAceitar: {
        backgroundColor: "#28a745",
        color: "white",
        marginTop: "8px",
        width: "min(150px, 40%)",
        "&:hover": {
            backgroundColor: "#28a745",
            filter: "brightness(1.05)",
        },
    },
    textoTermos: {
        color: "white",
    },
}))

export default function AvisoLGPD({ aceitarLGPD }) {
    const classes = useStyles()

    const textos = useTextos()

    const [textVariant] = useState(window.screen.width > 600 ? "body1" : "body2")

    return (
        <Paper square id="aviso-lgpd" className={classes.avisoLgpd}>
            <Typography className={classes.textoTermos} variant={textVariant}>
                {textos.textoLGPD}
            </Typography>
            <Typography className={classes.textoTermos} variant={textVariant}>
                <a href="https://appclientefiel.com.br/politicaprivacidade.html" target="_blank">
                    {textos.textoLinkLGPD}
                </a>
            </Typography>
            <Button
                className={classes.botaoAceitar}
                size="small"
                variant="outlined"
                onClick={() => setTimeout(aceitarLGPD, 500)}
            >
                {textos.aceitar}
            </Button>
        </Paper>
    )
}
